import type {UserPermissionType} from '@prisma/client'
import {isStaffEmail} from '@ambler/shared'
import {useAuth} from '../components/stack/auth'

const useHasPermission = (type: UserPermissionType) => {
  const {permissions = []} = useAuth()
  return permissions?.some(p => p.type === type)
}

export const useCanManageBoUsers = () => {
  const canManage = useHasPermission('BO_MANAGE_USERS')
  return process.env.AMBLER_STAGE?.startsWith('local') || canManage
}

export const useCanExportAnon = () => {
  const canExportAnon = useHasPermission('BO_EXPORT_ANONYMOUS')
  return canExportAnon
}

export const useCanExportFull = () => {
  const canExportFull = useHasPermission('BO_EXPORT_FULL')
  return canExportFull
}

export const useCanReadPatientIdentity = () => {
  const {user} = useAuth()
  const canViewPatientIdentity = useHasPermission('BO_PATIENT_IDENTITY_READ')
  return canViewPatientIdentity || !isStaffEmail(user.email)
}
